import {
  BaseApiApi,
  BaseMenuApi,
  BaseOperateApi,
  BaseRouteApi,
  BaseScheduleApi,
  BaseUserApi,
  BaseOrganizationApi,
  BaseLogApi,
  Configuration,
  BaseDictionaryApi,
  BaseTenantApi,
} from '@/api';


interface BaseApi {
  baseUrl: string;
  baseRouteApi: BaseRouteApi;
  baseUserApi: BaseUserApi;
  baseMenuApi: BaseMenuApi;
  baseApiApi: BaseApiApi;
  baseScheduleApi: BaseScheduleApi;
  baseOperateApi: BaseOperateApi;
  baseOrganizationApi: BaseOrganizationApi;
  baseLogApi: BaseLogApi;
  baseDictionaryApi: BaseDictionaryApi;
  baseTenantApi: BaseTenantApi;
}

const api: BaseApi = {

} as any;

export const initApi = (basePath: string) => {
  const apiConfig = new Configuration({
    basePath,
  });
  api.baseUrl = basePath;
  api.baseRouteApi = new BaseRouteApi(apiConfig, basePath);
  api.baseUserApi = new BaseUserApi(apiConfig, basePath);
  api.baseMenuApi = new BaseMenuApi(apiConfig, basePath);
  api.baseApiApi = new BaseApiApi(apiConfig, basePath);
  api.baseScheduleApi = new BaseScheduleApi(apiConfig, basePath);
  api.baseOperateApi = new BaseOperateApi(apiConfig, basePath);
  api.baseOrganizationApi = new BaseOrganizationApi(apiConfig, basePath);
  api.baseLogApi = new BaseLogApi(apiConfig, basePath);
  api.baseDictionaryApi = new BaseDictionaryApi(apiConfig, basePath);
  api.baseTenantApi = new BaseTenantApi(apiConfig, basePath);
};

export const useApi = () => api;

